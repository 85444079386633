import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import debounce from "lodash.debounce";

const BoughtSoldProductList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [allOrder, setAllOrder] = useState();
  const [Id, setId] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();
  const [showViewModal, setShowViewModal] = useState(false);

  const [userEmail, setUserEmail] = useState();
  const [orderDetail, setOrderDetail] = useState();

  useEffect(async () => {
    GetAllOrders();
  }, []);

  const GetAllOrders = async () => {
    await axios.get(`${PORT}/order/allorders`).then((response, err) => {
      //console.log("response",response)
      setAllOrder(response);
      // console.log(response);
    });
  };

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit"
    ) {
      GetAllOrders();
      toast.success("You have successfully edited ad");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
    props.location.MatchValue &&
    props.location.MatchValue == "edit",
  ]);

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "add"
    ) {
      GetAllOrders();
      toast.success("You have successfully added ad");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
    props.location.MatchValue &&
    props.location.MatchValue == "add",
  ]);

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("currentPage",currentPage,"postsPerPage",postsPerPage)
  const pagesVisited = currentPage * postsPerPage;
  // console.log("pagesVisited",pagesVisited)
  const pageCount =
    Math.ceil(
      (allOrder &&
        allOrder.data &&
        allOrder.data.data &&
        allOrder.data.data.length) / postsPerPage
    ) || 1;
  // console.log("pageCount",pageCount)
  const currentPosts =
    allOrder &&
    allOrder.data &&
    allOrder.data.data &&
    allOrder.data.data.slice(pagesVisited, pagesVisited + postsPerPage);
  // console.log("currentPosts",currentPosts)
  // const currentPosts = allBrand && allBrand.data && allBrand.data.data && allBrand.data.data.slice(indexOfFirstPost, indexOfLastPost);

  const calculateIndex = (index) => {
    // console.log("index",index,"currentPage",currentPage,"postsPerPage",postsPerPage)
    if (currentPage >= 1) {
      return currentPage * postsPerPage + index + 1;
    } else {
      return index + 1;
    }
  }
  const paginate = (pagenumber) => {
    setCurrentPage(pagenumber);
  };

  const handleSearch = async (event) => {
    setSearchItem(event.target.value);
    var search = event.target.value;
    if (search) {
      await axios
        .get(`${PORT}/order/allorders/?search=${search}`)
        .then((response, err) => {
          // console.log("total product", response);
          setCurrentPage(0)
          setAllOrder(response);
        });
    } else {
      setCurrentPage(0);
      GetAllOrders();
    }
  };

  const handlePageChange = ({ selected }) => {
    // console.log("selectedpage", selected);
    //  console.log(pageCount);
    setCurrentPage(selected);
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteReport(Id);
    setDeleteModal(false);
  };

  const handleDeleteReport = async (id) => {
    await axios
      .post(`${PORT}/reportAdDelete`, { params: { id } })
      .then((response, err) => {
        toast.success("You have successfully deleted this ad");
      });
    await axios.get(`${PORT}/reportAd`).then((response, err) => {
      setAllOrder(response);
    });
  };

  const getReport = (report_id) => {
    var report = "";
    for (var i = 0; i < report_id.length; i++) {
      report += report_id[i].name + ",";
    }
    report = report.replace(/,\s*$/, "");
    return <div>{report}</div>;
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), []);

  const DateDiffrence = (date) => {
    const date2 = date.split("T")[0];
    const datee = new Date();
    const todayDate = datee.getDate();
    const todayMonth = datee.getMonth() + 1;
    const todayYear = datee.getFullYear();
    const date1 = todayYear + "-" + todayMonth + "-" + todayDate;

    const date11 = new Date(`${date1}`);
    const date22 = new Date(`${date2}`);
    // console.log(
    //   "datedatedate",
    //   date1,
    //   date2,
    //   datee,
    //   todayDate,
    //   todayMonth,
    //   todayYear
    // );

    const diffInMs = Math.abs(date22 - date11);
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  };

  const orderStatusHandler = async (orderId, status, index, auction) => {
    let orderStatus = Number(status);
    if (auction == 1) {
      await axios
        .post(`${PORT}/liveStreaming/changeLiveAuctionStatus`, { id: orderId, status: orderStatus })
        .then((response, err) => {
          GetAllOrders();
          toast.success("Order status successfully change");
        });
    } else {
      await axios
        .post(`${PORT}/order/changeOrderStatus`, { orderId, orderStatus })
        .then((response, err) => {
          GetAllOrders();
          toast.success("Order status successfully change");
        });
    }
  };

  const sellerRequestHandler = async (orderId, sellerRequest) => {
    await axios
      .post(`${PORT}/order/changeSellerRequestStatus`, { orderId, sellerRequest })
      .then((response, err) => {
        GetAllOrders();
        toast.success("Order money release successfully");
      });
  };

  const customStyles = {
    content: {
      width: '60%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
    },
  };

  const viewModal = (order) => {
    let shipping = order?.shipping_address;
    let name = shipping?.name?.split(" ");
    let firstName = name ? name[0] : '';
    let lastName = name ? name[1] : '';
    order.shipping_address = shipping ? `${firstName} ${lastName}, ${shipping.address?.line1}, ${shipping.address?.line2}, ${shipping.address?.city}, ${shipping.address?.country}, ${shipping.address?.postal_code
      }, ${shipping.address?.mobileNumber}` : "";
    setOrderDetail(order);
    setShowViewModal(true);
  };

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Bought & Sold Products</li>
              </ul>
              <h2 class="main_title">Bought & Sold Products</h2>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  {/* <div class="dropdownsid">
                    <select class="fuilddesh">
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div> */}
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={debouncedChangeHandler}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder="By title"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts BoughtSoldProductList_table">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">#ID</div>
                        <div class="table_cell">Title</div>
                        <div class="table_cell">Buyer</div>
                        <div class="table_cell">Seller</div>
                        <div class="table_cell">Amount</div>
                        <div class="table_cell">Posted</div>
                        <div class="table_cell">Status</div>
                        <div class="table_cell">Order</div>
                        <div class="table_cell">Action</div>
                      </div>
                      {currentPosts &&
                        currentPosts
                          // .filter((val) => {
                          //   if (searchItem == null) {
                          //     return val;
                          //   } else if (
                          //     val.name
                          //       .toLowerCase()
                          //       .includes(searchItem.toLowerCase())
                          //   ) {
                          //     return val;
                          //   } else {
                          //     return false;
                          //   }
                          // })
                          .map((order, index) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div class="table_cell">#{calculateIndex(index)}</div>
                                <div class="table_cell">
                                  {order.product_id && (
                                    <>
                                      <p>
                                        {order.product_id.productName
                                          ? order.product_id.productName
                                          : ""}
                                      </p>
                                      <div class="catetabl_image">
                                        <div class="cateimag">
                                          <div class="acttiimmg">
                                            {/* <img
                                            src="images/profile-img.jpg"
                                            alt="image"
                                        /> */}
                                            {order.product_id
                                              .productMainImage ? (
                                              <img
                                                src={
                                                  `${PORT}/image/products/resize-180-180/` +
                                                  order.product_id
                                                    .productMainImage
                                                }
                                                alt={
                                                  order.product_id.productName
                                                }
                                              />
                                            ) : (
                                              <img
                                                src="/images/no-image.jpg"
                                                alt="product"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div class="catetlist">
                                          <div class="listofca_tbl">
                                            <div class="listosa_tbl">
                                              <div class="leftlist_tbl">
                                                Category:
                                              </div>
                                              <div class="rightlist_tbl">
                                                {
                                                  order.product_id.categoryId
                                                    ?.categoryName
                                                }
                                              </div>
                                            </div>
                                            <div class="listosa_tbl">
                                              <div class="leftlist_tbl">
                                                Price:
                                              </div>
                                              <div class="rightlist_tbl">
                                                &#x20A6;{" "}
                                                {order.product_id.productPrice}
                                              </div>
                                            </div>
                                            <div class="listosa_tbl">
                                              <div class="leftlist_tbl">
                                                Email:
                                              </div>
                                              <div class="rightlist_tbl">
                                                {order.product_id.userId?.email}
                                              </div>
                                            </div>
                                            <div class="listosa_tbl">
                                              <div class="leftlist_tbl">
                                                Mobile:
                                              </div>
                                              <div class="rightlist_tbl">
                                                {
                                                  order.product_id.userId
                                                    ?.mobileNumber
                                                }
                                              </div>
                                            </div>
                                            <div class="listosa_tbl">
                                              <div class="leftlist_tbl">
                                                Location:
                                              </div>
                                              <div class="rightlist_tbl">
                                                {order.product_id.location}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div class="table_cell">
                                  {order?.buyer_id?.firstName
                                    ? order.buyer_id.firstName
                                    : ""}
                                  {/* {order?.buyer_id?.lastName
                                    ?
                                    order.buyer_id.lastName
                                    : ""} */}
                                </div>
                                <div class="table_cell">
                                  {order.seller_id
                                    ? order.seller_id.firstName +
                                    " " +
                                    order.seller_id.lastName
                                    : ""}
                                </div>
                                <div class="table_cell">
                                  &#x20A6; {order.amount}
                                </div>
                                <div class="table_cell">
                                  {DateDiffrence(order.created_at)} Days ago
                                  <p>Added: {order.created_at.split("T")[0]}</p>
                                </div>
                                <div class="table_cell text-center">
                                  {/* <span className="labltable lablesct">
                                    {order.status}
                                  </span><br/> */}
                                  <span className="labltable lablesct">
                                    {(() => {
                                      if (order.orderStatus === 0) {
                                        return "Order Posted";
                                      } else if (order.orderStatus === 1 || order.orderStatus == 7) {
                                        return "Cancel Order";
                                      } else if (order.orderStatus === 2) {
                                        return "Shipped";
                                      } else if (order.orderStatus === 3) {
                                        return "Delivered";
                                      } else if (order.orderStatus === 4) {
                                        return "Completed";
                                      } else if (order.orderStatus === 5) {
                                        return "Suspend";
                                      } else if (order.orderStatus === 6) {
                                        return "Suspend";
                                      }
                                    })()}
                                  </span><br />
                                  {order.sellerRequest === 2 ? (<span className="labltable lablesct mt-1">
                                    Release Money
                                  </span>) : (order.sellerRequest === 1 ? (<span className="labltable lablesct mt-1">
                                    Request Money
                                  </span>) : "")}

                                </div>
                                {order.orderStatus == 2 && (
                                  <div className="table_cell">
                                    <p className="subtab_editlabl select_bx">
                                      <select
                                        className="form-control labltable lablesct"
                                        value={order?.orderStatus}
                                        onChange={(e) =>
                                          orderStatusHandler(
                                            order._id,
                                            e.target.value,
                                            index,
                                            order.is_auction
                                          )
                                        }
                                      >
                                        <option value="">Select Option</option>
                                        <option value="1">
                                          Cancel Order{" "}
                                        </option>
                                        <option value="3">
                                          Delivered{" "}
                                        </option>
                                        <option value="4">
                                          Completed{" "}
                                        </option>
                                        <option value="14">
                                          Chargeback{" "}
                                        </option>

                                      </select>
                                    </p>
                                  </div>
                                )}
                                {(order.orderStatus == 1 || order.orderStatus == 7) && (
                                  <div className="table_cell">
                                    <p className="subtab_editlabl select_bx">
                                      <select
                                        className="form-control labltable lablesct"
                                        value={order?.orderStatus}
                                        onChange={(e) =>
                                          orderStatusHandler(
                                            order._id,
                                            e.target.value,
                                            index,
                                            order.is_auction
                                          )
                                        }
                                      >
                                        <option value="">Select Option</option>

                                      </select>
                                    </p>
                                  </div>
                                )}
                                {(![2, 1, 7, 4, 3].includes(order.orderStatus)) && (
                                  <div className="table_cell">
                                    <p className="subtab_editlabl select_bx">
                                      <select
                                        className="form-control labltable lablesct"
                                        value={order?.orderStatus}
                                        onChange={(e) =>
                                          orderStatusHandler(
                                            order._id,
                                            e.target.value === "1" ? (order.is_auction === 0 ? "1" : "7") : e.target.value,
                                            index,
                                            order.is_auction
                                          )
                                        }
                                      >
                                        <option value="">Select Option</option>
                                        <option value="1">
                                          Cancel Order{" "}
                                        </option>
                                        <option value="3">
                                          Delivered{" "}
                                        </option>
                                        <option value="4">
                                          Completed{" "}
                                        </option>

                                      </select>
                                    </p>
                                  </div>
                                )}
                                {order.orderStatus == 4 && (
                                  <div className="table_cell">
                                    <p className="subtab_editlabl select_bx">
                                      <select
                                        className="form-control labltable lablesct"
                                        value={order?.orderStatus}
                                        onChange={(e) =>
                                          orderStatusHandler(
                                            order._id,
                                            e.target.value,
                                            index,
                                            order.is_auction
                                          )
                                        }
                                      >
                                        <option value="">Select Option</option>
                                        <option value="14">
                                          Chargeback{" "}
                                        </option>

                                      </select>
                                    </p>
                                  </div>
                                )}
                                {order.orderStatus == 3 && (
                                  <div className="table_cell">
                                    <p className="subtab_editlabl select_bx">
                                      <select
                                        className="form-control labltable lablesct"
                                        value={order?.orderStatus}
                                        onChange={(e) =>
                                          orderStatusHandler(
                                            order._id,
                                            e.target.value,
                                            index,
                                            order.is_auction
                                          )
                                        }
                                      >
                                        <option value="">Select Option</option>
                                        <option value="4">
                                          Completed{" "}
                                        </option>
                                        <option value="14">
                                          Chargeback{" "}
                                        </option>

                                      </select>
                                    </p>
                                  </div>
                                )}
                                <div class="table_cell">
                                  <button className="btn-sm btn-success" onClick={() => viewModal(order)}>View</button>
                                  {order.sellerRequest === 1 ? (<button className="btn-success btn-sm mt-1" onClick={() => sellerRequestHandler(order._id, 2)}>
                                    <i class="fa fa-money" aria-hidden="true"></i>
                                  </button>) : ""}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            {/* Showing {currentPage + 1}-{pageCount} of {pageCount} */}
            {(currentPosts && currentPosts.length > 0) ? (`Showing ${currentPage + 1}-${pageCount} of ${pageCount}`) : (`Showing 0-0 of 0`)}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal isOpen={deleteModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to delete this ad?</h6>
          </div>
          <Button
            onClick={handleDeleteYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>
      {showViewModal && (
        <Modal isOpen={showViewModal}
          onRequestClose={() =>
            setShowViewModal(false)
          }
          style={customStyles}
          className="soldproducttable"
          ariaHideApp={false}
        >
          <div className="soldproduct_block">
            <div>
              <button onClick={() => { setShowViewModal(false); setOrderDetail(""); }}>
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="data_soldproducttable">
              <div className="row">
                <strong className="col-4">Product Name : </strong>
                <strong className="col-8">{orderDetail?.product_id.productName}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Product Category : </strong>
                <strong className="col-8">{orderDetail?.product_id?.categoryId?.categoryName}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Product Price : </strong>
                <strong className="col-8">{orderDetail?.product_id.productPrice}</strong>
              </div>
              <div className="row">
                <strong className="col-md-4">Total Amount : </strong>
                <strong className="col-md-8">{orderDetail?.amount}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Location : </strong>
                <strong className="col-8">{orderDetail?.product_id.location}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Buyer Name : </strong>
                <strong className="col-8">{orderDetail?.buyer_id.firstName}  {orderDetail?.buyer_id.lastName}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Buyer Email : </strong>
                <strong className="col-8">{orderDetail?.buyer_id.email}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Seller Name : </strong>
                <strong className="col-8">{orderDetail?.seller_id.firstName}  {orderDetail?.seller_id.lastName}</strong>
              </div>
              <div className="row">
                <strong className="col-4">Shipping Address : </strong>
                <strong className="col-8">{orderDetail?.shipping_address}</strong>
              </div>
              {orderDetail?.expected_delivery_date ? (
                <div className="row">
                  <strong className="col-4">Expected Delivery Date : </strong>
                  <strong className="col-8">{(orderDetail?.expected_delivery_date && orderDetail?.expected_delivery_date !== null) && (orderDetail?.expected_delivery_date.split("T")[0])}</strong>
                </div>
              ) : null}
              {orderDetail.shipping_note ? (
                <div className="row">
                  <strong className="col-4">Shipping Note : </strong>
                  <strong className="col-8">{orderDetail?.shipping_note}</strong>
                </div>
              ) : null}
              {orderDetail.shipping_note ? (
                <div className="row">
                  <strong className="col-4">Return Shipping Note : </strong>
                  <strong className="col-8">{orderDetail?.return_shipping_note}</strong>
                </div>
              ) : null}

              {orderDetail?.orderStatus > 4 && (<>
                <div className="row">
                  <strong className="col-4">Return Reson : </strong>
                  <strong className="col-8">{(orderDetail?.reson_id !== null) ? orderDetail?.reson_id.name : orderDetail?.other_reson}</strong>
                </div>
                <div className="row">
                  <strong className="col-4">Retrun Status : </strong>
                  <strong className="col-8">{orderDetail?.return_status}</strong>
                </div>
                {orderDetail?.return_status === "Accepted" && (
                  <div className="row">
                    <strong className="col-4">Retrun Address : </strong>
                    <strong className="col-8">{orderDetail?.return_address}</strong>
                  </div>)}
              </>)}
              <div className="row">
                <strong className="col-4">Status : </strong>
                <strong className="col-8">
                  {(() => {
                    if (orderDetail.orderStatus === 0) {
                      return "Order Posted";
                    } else if (orderDetail.orderStatus === 1) {
                      return "Cancel Order";
                    } else if (orderDetail.orderStatus === 2) {
                      return "Shipped";
                    } else if (orderDetail.orderStatus === 3) {
                      return "Delivered";
                    } else if (orderDetail.orderStatus === 4) {
                      return "Completed";
                    } else if (orderDetail.orderStatus === 5) {
                      return "Suspend";
                    } else if (orderDetail.orderStatus === 6) {
                      return "Suspend";
                    }
                  })()}
                </strong>
              </div>
              <div className="row">
                <strong className="col-md-4">Product Image : </strong>
                <div className="col-md-8">
                  <div className="img-block">
                    {orderDetail.product_id
                      .productMainImage ? (
                      <img
                        src={
                          `${PORT}/image/products/resize-180-180/` +
                          orderDetail.product_id
                            .productMainImage
                        }
                        alt={
                          orderDetail.product_id.productName
                        }
                        width={100} />
                    ) : (
                      <img
                        src="/images/no-image.jpg"
                        alt="product"
                        width={100}
                      />
                    )}
                  </div> </div>
              </div>
              <div className="row">
                <strong className="col-md-4">Order Posted : </strong>
                <strong className="col-md-8">
                  {DateDiffrence(orderDetail.created_at)} Days ago
                  <p>
                    Added: {orderDetail.created_at.split("T")[0]}
                  </p>
                </strong>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BoughtSoldProductList;
